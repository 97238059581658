<template>
  <div class="index-body">
    <div class="banner">
      <span>资讯</span>
    </div>
    <!-- <div class="brief-introduction" :style="{ display: active ? 'flex' : '' }">
      <section class="info">
        <figure>
          <img src="../../assets/recommend/managineInfo.png" alt="" />
        </figure>
        <ul>
          <li>创刊时间：2020年3月</li>
          <li>ISSN：978-7-5166-6362</li>
          <li>主管：中党史出版</li>
          <li>主办：中党史出版社</li>
          <li>承办：中党史出版社</li>
        </ul>
      </section>
      <section class="title">
        <div class="infomsg">
          《创新时代》杂志是国家知识产权局主管，中国发明协会、北京中图联文化教育开发中心联合主办，创新时代杂志社出版，国家级社会文化期刊.
        </div>
      </section>
    </div> -->
    <div class="mian-body" :style="{ display: show ? 'block' : '' }">
      <div class="sidebar">
        <el-menu class="el-menu-vertical-demo" :default-active="'' + defaultOpends">
          <el-menu-item
            :index="item.defaultOpends"
            v-for="item in defaultOpendsList"
            @click="changeYear(item.defaultOpends)"
            :key="item.defaultOpends"
          >
            <span>{{ item.name }}({{ item.total }})</span>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="container">
        <!-- <section class="button">
          <div
            :class="defaultOpends == '0' ? 'active' : ''"
            @click="changeYear(0)"
          >
            图片资讯
          </div>
          <div
            :class="defaultOpends == '1' ? 'active' : ''"
            @click="changeYear(1)"
          >
            其他资讯
          </div>
        </section> -->

        <ul class="books" v-if="picdataList.length > 0 && defaultOpends == '0'"    ref="books">
      
          <li
            v-for="item in picdataList"
            class="book"
            :key="item.id"
            @click="goPicDetail(item.id)"
          >
            <figure>
              <img :src="item.titlePicUrl" alt="" />
            </figure>
            <div class="books-msg">
             <span v-html="keySign( item.title )"></span>
                  <span v-html=" keySign(decode(item.infoContent) )"></span>
              <span>{{ item.publishTime }}</span>
            </div>
          </li>
          
           
        </ul>
         <div v-if="picdataList.length <= 0 && defaultOpends == '0' " class="titlekb">暂无结果，请尝试其他关键词</div>
        <ul class="books2" v-if="dataList.length > 0 && defaultOpends == '1' "  ref="books">
          <li
            v-for="item in dataList"
            class="book"
            :key="item.id"
            @click="goDetail(item.id)"
          >
            <span v-html="keySign( item.title )"></span>
               
            <span>{{ item.createDate.substring(0, 10) }}</span>
              <span v-html=" keySign(decode(item.infoContent) )"></span>
          </li>
      
        </ul>
                <div  v-if="dataList.length <= 0 && defaultOpends == '1' " class="titlekb">暂无结果，请尝试其他关键词</div>
        <div class="pagination" ref="bottom">
          <el-button :disabled="dataForm.firstPageDisabled" @click="goPageOne">
            首页</el-button
          >
          <el-pagination
            :pager-count="5"
            background
            :current-page="dataForm.page"
            :page-size="dataForm.limit"
            layout="prev, pager, next"
            :total="dataForm.total"
            @current-change="pageCurrentChangeHandle"
          >
          </el-pagination>
          <el-button :disabled="dataForm.lastPageDisabled" @click="goPageLast"
            >末页</el-button
          >
          <span class="go">跳转</span>

          <input :value="goPage" @input="handleInput" defaultOpends="text" />
          <span class="page">页</span>
          <el-button class="to" @click="go()">跳转</el-button>
        </div>
      </div>
    </div>
    <div class="pickerOut" v-if="iPhoneShow">
      <van-picker
        class="picker"
        show-toolbar
        title="选择年份"
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </div>
      <ul v-if="workName == 'third'" class="banner_info">
      <li @click="select(0)" :class="defaultOpends == 0 ? 'active' : ''">图片资讯({{defaultOpendsList[0].total}})</li>
      <li @click="select(1)" :class="defaultOpends == 1 ? 'active' : ''">其他资讯({{defaultOpendsList[1].total}})</li>
    </ul>
  </div>
</template>

<script>
 
export default {
  name: 'vue-index',

  data () {
    return {
      msg: '',
      columns: [],
      dataList: [],
      picdataList: [],
      total: 0,
      active: false,
      active2:false,
      show: false,
      iPhoneShow: false,
      yearsList: [],
      dataForm: {
        // year: "2023",
        status: 1,
        // defaultOpends:'图片资讯',
        limit: 7,
        page: 1
      },

      defaultOpends: '0',
      goPage: '',
      defaultOpendsList: [
        { defaultOpends: '0', name: '图片资讯', size: '' ,total:0},
        { defaultOpends: '1', name: '其他资讯', size: '',total:0 }
      ]
    }
  },
 
   props: {
    workName: String,
  },
  async created () {
     this.dataForm.keyword = this.$route.query && this.$route.query.keyword;
    // await this.queryAll()

    this.query()
    this.queryPic()

    this.defaultOpends = this.$route.query.publishRange
    if (!this.defaultOpends) {
      this.defaultOpends = '0'
    }

    // await this.selcetQuery();
    this.scrollWindow()
  },
  methods: {
    goDetail (id) {
      this.$router.push({
        path: '/information/informationdetail',
        query: { id: id }
      })
    },
        keySign(title) {
      let s = this.$route.query.keyword; // 搜索框的值(您要标红的关键字)
      var str = title; // 列表标题(原文本)

      // 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(new RegExp(/\s+/g), ","); // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); // 把字符串分割转换成数组(方便截取)
      // 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g");
          // 如果匹配成功则抛出关键字DOM
          // TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(
            reg,
            '<i style="color:red;font-weight: bold;">' + e + "</i>"
          ); // 改变搜索关键字颜色为红色
        });
      }
      return str;
    },
      select(active) {
      this.defaultOpends = active;
      this.$emit("workNameChange", "other");
    },
       decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      var test = base64 && base64.replaceAll(/<[^>]+>/g, "");
      return test;
    },
    goPicDetail (id) {
      this.$router.push({
        path: '/information/picinformationdetail',
        query: { id: id }
      })
    },
    pageCurrentChangeHandle (index) {
      this.dataForm.page = index

      this.selcetQuery()
    },
    handleInput (value) {
      this.goPage = +value.data
    },
    go () {
      this.dataForm.page = this.goPage

      this.selcetQuery()
    },
    showmsg () {
      this.active = !this.active
      console.log(this.active)
    },

    changeYear (defaultOpends) {
      this.defaultOpends = '' + defaultOpends
      this.dataForm.page = 1

      this.selcetQuery()
    },
    onConfirm (value) {
      this.dataForm.year = value

      this.selcetQuery()
      this.iPhoneShow = !this.iPhoneShow
    },
    onCancel () {
      this.iPhoneShow = !this.iPhoneShow
    },
    showSelect () {
      this.iPhoneShow = !this.iPhoneShow
      console.log('qqqqq')
    },
    confirm () {
      this.show = !this.show
    },
    selectYears (list) {
      this.yearsList = []
      this.columns = []
      let obj = {}
      this.yearsList = list.reduce(function (item, next) {
        obj[next.year] ? '' : (obj[next.year] = true && item.push(next))
        return item
      }, [])
      this.yearsList.sort((a, b) => b.year - a.year)
      this.yearsList.forEach(year => {
        this.columns.push(year.year)
      })
    },
    async queryAll () {
      console.log('resdata')

      const { data: res } = await this.$http.get('/magazine/homePage', {
        params: {
          status: 1
        }
      })

      if (res.code !== 0) {
        this.dataList = []
        this.total = 0
        return this.$message.error(res.msg)
      }
   
    },
    goPageOne () {
      this.dataForm.page = 1
      this.selcetQuery()
    },
    goPageLast () {
      this.dataForm.page = Math.ceil(this.dataForm.total / this.dataForm.limit)
      this.selcetQuery()
    },
    selcetQuery () {
      if (this.defaultOpends == 0) {
        this.dataForm.limit = 8
        this.queryPic()
      } else {
        this.dataForm.limit = 7
        if (
          navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
          )
        ) {
          this.dataForm.limit = 20
        }
        this.query()
      }
    },
    scrollWindow () {
      window.addEventListener('scroll', () => {
        if (
          navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
          )
        ) {
          //显示移动端的代码
          this.getMainBox()
        } else {
          console.log('11')
        }
      })
    },
    async getMainBox () {
      let Main = this.$refs.books
      let bottom = this.$refs.bottom //底部的相关DOM
      // console.log(Main.offsetTop);      //父盒子距离浏览器顶部的高度
      // console.log(window.innerHeight);  //浏览器的高度，即页面窗口的高度
      if (!Main && !Main.clientHeight) {
        return
      } //父盒子的高度(加上padding和margin)
      console.log(
        window.scrollY,
        Math.abs(
          Main.clientHeight -
            window.innerHeight -
            (window.scrollY - Main.offsetTop - bottom.clientHeight)
        )
      ) //浏览器右边的滚动条滚动距离
      if (
        Math.abs(
          Main.clientHeight -
            window.innerHeight -
            (window.scrollY - Main.offsetTop - bottom.clientHeight)
        ) <= 10
      ) {
        console.log('加载重')
        this.dataForm.page += 1
        if (this.defaultOpends == 0) {
          this.dataForm.limit = 8

          let { data: res } = await this.$http.get('/picinformation/homePage', {
            params: {
              ...this.dataForm
            }
          })
          this.picdataList = this.picdataList.concat(res.data.list)
        } else {
          this.dataForm.limit = 7
          let { data: res } = await this.$http.get('/information/homePage', {
            params: {
              ...this.dataForm
            }
          })
          this.dataList = this.dataList.concat(res.data.list)
        }

        // alert('12323132')
        //这里在运用在获取新的相关数据即可
      }
    },
    query () {
      this.$http
        .get('/information/homePage', {
          params: {
            ...this.dataForm
          }
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.dataList = []
            this.total = 0
            return this.$message.error(res.msg)
          }
          this.dataList = res.data.list
          this.defaultOpendsList[1].total = res.data.total
             this.$emit('picInfoTotal',res.data.total)
          this.dataForm.total = res.data.total
        })
        .catch(() => {})
    },
    queryPic () {
        console.log(this.picdataList,'picdataList');
      this.$http
        .get('/picinformation/homePage', {
          params: {
            ...this.dataForm,
            keyword:this.dataForm.keyword
          }
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.picdataList = []
            this.dataForm.total = 0
            return this.$message.error(res.msg)
          }
          this.picdataList = res.data.list
          console.log(this.picdataList,'picdataList');

          this.dataForm.total = res.data.total
          this.defaultOpendsList[0].total = res.data.total
          this.$emit('InfoTotal',res.data.total)
        })
        .catch(() => {})
    }
  },
  watch: {
     $route(to, from) {
      location.reload()()
      console.log("当前页面路由2：" + to.path);
      console.log("上一个路由2：" + from);
    },
  }
}
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 0.56rem;
  height: 3.3rem;
  background: linear-gradient(180deg, #b20103 0%, #f8f8f8 100%);

  span {
    position: relative;
    left: 0.84rem;
    top: 0.64rem;
    font-size: 0.48rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.56rem;
  }
}
.pagination {
  position: absolute;
  right: 0.32rem;
  //   margin-top: 0.16rem;
  bottom: 0.32rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin: 0 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    text-align: center;
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  input:focus-visible {
    outline: none;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  // /deep/ .el-input__inner {
  //   width: 0.32rem;
  //   height: 0.32rem;
  //   background: #ffffff;
  //   border-radius: 0.04rem;
  //   border: 0.01rem solid #eeeeee;
  // }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  /deep/ .el-pagination .btn-prev .el-icon {
    content: '首页';
  }
  /deep/ .el-icon-arrow-left:before {
    content: '上一页';
         font-size: .14rem;
  }
  /deep/ .el-icon-arrow-right::before {
    content: '下一页';
         font-size: .14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
  }
  /deep/.el-button span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
  }
  /deep/ .el-button.is-disabled span {
    color: #c0c4cc;
  }
  .el-pagination .btn-prev .el-icon {
    content: '首页';
  }
  /deep/ .el-icon-arrow-left:before {
    content: '上一页';
         font-size: .14rem;
  }
  /deep/ .el-icon-arrow-right::before {
    content: '下一页';
         font-size: .14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
    color: #ffffff;
  }
}
.mian-body {
  position: relative;
  top: -1.2rem;
  // height: 8.65rem;
  padding: 0 0.64rem;
  margin-bottom: -0.88rem;
  .topone {
    padding-left: 0.32rem !important;
    height: 0.56rem;
    margin-bottom: 0;
  }
  .topone:hover {
    background: #ffffff;
  }
  .topone.is-active {
    background: #ffffff;
  }
  .topone.is-active::after {
    display: none;
  }
}
.mian-body {
  display: flex;
  justify-content: space-between;
}
.sidebar {
  width: 2.4rem;
  height: 6.3rem;
  background: #ffffff;
  overflow: scroll;
}
.container {
  position: relative;
  box-sizing: border-box;
  width: 10.56rem;
  height: 6.3rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  padding: 0rem 0.32rem;
  .books {
    display: flex;
    flex-wrap: wrap;
    min-height: 80vh;
    // justify-content: space-between;
    cursor: pointer;
    padding-top: 0.32rem;

    li {
      margin-right: 0.22rem;
      margin-bottom: 0.32rem;
      display: flex;
      flex-direction: column;
    }
    li:nth-child(4n) {
      margin-right: 0;
    }
    figure {
      width: 2.3rem;
      height: 1.46rem;
      // background: #ffffff;

      // border: 0.01rem solid #eeeeee;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .books-msg {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.24rem;

      font-size: 0.16rem;
      text-align: center;
      display: inline-block;
      width: 2.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 0.16rem;
      span:nth-child(2) {
        display: none;
      }
    }
  }
  .books2 {
    padding-top: 0.08rem;
        min-height: 80vh;
    li {
      height: 0.72rem;
      line-height: 0.72rem;
   

      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      &:first-child {
        span {
          &:first-child {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }

      span:first-child {
        width: 8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      span {
        &:hover {
          cursor: pointer;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
}

/deep/ .el-icon-arrow-down:before {
  color: var(--backgroundColor);
  transition: 0.3s all;
}
/deep/ .el-icon-arrow-up::before {
  color: var(--backgroundColor);
  transition: 0.3s all;
}
/deep/ .el-menu-item {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.48rem;
  line-height: 0.48rem;
  padding-left: 0.64rem !important;
  position: relative;
  margin-bottom: 0.08rem;
}
/deep/ .el-submenu__title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.56rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
}
/deep/.el-menu-item:hover {
  background: #eeeeee;
}
/deep/.el-submenu__title:hover {
  background: #ffffff;
}
/deep/ .el-menu-item.is-active {
  background: rgba(195, 40, 43, 0.1);
}

.el-menu-item.is-active::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: '';
  width: 0.04rem;
  height: 0.48rem;
  background: #c3282b;
}

.pagination {
  position: absolute;
  right: 0.32rem;
  margin-top: 0.47rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin-right: 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input__inner {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
}

.brief-introduction {
  position: relative;
  top: -1.22rem;
  margin: 0 0.64rem;
  width: 13.1rem;
  height: 2.22rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  margin-bottom: 0.16rem;
  display: flex;
  .info {
    padding: 0.3rem 0.32rem;
    border-right: 2px dashed rgba(238, 238, 238, 1);
    width: 7.4rem;
    display: flex;
    figure {
      width: 2.4rem;
      height: 1.58rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.32rem;
      // align-items: center;
      margin-top: 0.3rem;
      li {
        width: 1.7rem;
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        // line-height: 0.22rem;
        &:nth-child(2) {
          width: 1.82rem;
        }
      }
    }
  }
  .title {
    width: 50%;

    .infomsg {
      margin: 0.62rem 1.08rem 0.64rem 0.8rem;
      font-size: 0.14rem;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.32rem;
    }
  }
}
.button {
  display: none;
}
@media (max-width: 768px) {
  .index-body {
    margin-top: 0;
    padding-bottom: 0.44rem;
    padding-left: 0;
    background: #ffffff;

    .mian-body {
      height: auto;
      top: 0;
      padding: 0;
      margin-bottom: 0;

      .container {
        padding: 0;
        width: 3.75rem;
        height: auto;
        .button {
          // width: 3.75rem;
          height: 0.56rem;
          background: #ffffff;
          display: flex;
          // justify-content: flex-end;
          align-items: center;
          z-index: 9;
          position: relative;
          margin-left: 0.14rem;
          div {
            font-size: 0.14rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 0.2rem;
            margin-right: 0.24rem;

            &.active {
              font-size: 0.14rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              line-height: 0.2rem;
              &::after {
                content: '';
                display: block;
                width: 0.24rem;
                height: 0.03rem;
                background: #b20103;
                border-radius: 0.02rem;
                position: relative;
                left: 50%;
                transform: translate(-50%);
                top: 0.05rem;
              }
            }
          }
        }
        .books2 {
          border-top: 1px solid #eeeeee;
          margin: 0 0.15rem;
          align-items: center;
          li {
            position: relative;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
            height: 0.78rem;
            // line-height: 0.34rem;
            width: 3.45rem;
            margin: 0;
            align-items: center;
            &::after {
              position: absolute;
              right: 0;
              content: '';
              width: 0.06rem;
              height: 0.11rem;
              background: url('../../../assets/recommend/more.png');
              background-size: 100% 100%;
            }
            &:first-child {
              span {
                &:first-child {
                  cursor: pointer;
                  color: rgba(0, 0, 0, 0.85);
                }
              }
            }
            span {
              font-size: 0.14rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.45);
              line-height: 0.32rem;
              width: 90%;
               &:first-child {
                  cursor: pointer;
                  color: rgba(0, 0, 0, 0.85);
                }
            }
            span:nth-child(2) {
              display: none;
            }
            span:nth-child(3) {
            font-size: 0.13rem;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: rgba(0,0,0,0.65);
              line-height: 0.2rem;
                 overflow: hidden; //超出文本隐藏

              text-overflow: ellipsis; ///超出部分省略号显示

              display: -webkit-box; //弹性盒模型

              -webkit-box-orient: vertical; //上下垂直

              -webkit-line-clamp: 1; //自定义行数
            }
          }
        }
        .books {
          margin-left: 0.15rem;
          padding-top: 0.16rem;
   display: block;
          li {
            flex-direction: row;
         
            margin-right: 0;
          }
          figure {
            width: 1.2rem;
            height: 0.76rem;
            border-radius: 5px;
            overflow: hidden;
            border: none;
            margin-right: 0.16rem;
          }
          .books-msg {
            display: flex;
            flex-direction: column;
            margin: 0;
            text-align: left;
            width: 2.09rem;
            span:nth-child(1) {
              height: 0.44rem;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.9);
              line-height: 0.22rem;
              height: 0.22rem;
              font-size: 0.14rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.9);
              line-height: 0.22rem;
              width: 1.8rem;

              overflow: hidden; //超出文本隐藏

              text-overflow: ellipsis; ///超出部分省略号显示

              display: -webkit-box; //弹性盒模型

              -webkit-box-orient: vertical; //上下垂直

              -webkit-line-clamp: 1; //自定义行数
            }
            span:nth-child(2) {
              display: inline-block;
              font-size: 0.12rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.45);
              line-height: 0.17rem;
               height: 0.17rem;
              margin-top: .08rem;
               overflow: hidden; //超出文本隐藏

              text-overflow: ellipsis; ///超出部分省略号显示

              display: -webkit-box; //弹性盒模型

              -webkit-box-orient: vertical; //上下垂直

              -webkit-line-clamp: 1; //自定义行数
            }
             span:nth-child(3) {
              display: inline-block;
              font-size: 0.12rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.45);
              line-height: 0.17rem;
              margin-top: .08rem;
            }
          }
        }
      }

      .sidebar {
        display: none;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;

        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        .sidebarInner {
          width: 3rem;
          height: 100vh;
          background: #ffffff;
          border-radius: 0rem 0.16rem 0.16rem 0rem;
          overflow: hidden;
        }
        .sidebarTop {
          margin-top: 0.6rem;
          margin-bottom: 0.07rem;
          margin-left: 0.3rem;
          display: flex;
          span {
            width: 0.64rem;
            height: 0.24rem;
            font-size: 0.16rem;

            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            line-height: 0.24rem;
            margin-left: 0.62rem;
          }
        }

        .buttons {
          margin: 0 0.56rem;
          margin-top: 0.32rem;
          display: flex;
          justify-content: space-between;

          .reset {
            width: 0.86rem;
            height: 0.32rem;
            background: #fff4f4;
            border-radius: 0.04rem;

            font-size: 0.14rem;

            font-weight: 400;
            color: #c3282b;
            line-height: 0.32rem;
            text-align: center;
          }
          .confirm {
            width: 0.86rem;
            height: 0.32rem;
            background: #c3282b;
            border-radius: 0.04rem;
            text-align: center;
            font-size: 0.14rem;

            font-weight: 400;
            color: #ffffff;
            line-height: 0.32rem;
          }
        }
      }
    }
    .brief-introduction {
      display: none;
      position: absolute;
      height: 3.13rem;
      width: auto;
      top: 1.1rem;
      left: 0;
      z-index: 9;
      margin: 0;
      flex-direction: column-reverse;
      height: auto;

      figure {
        display: none;
      }
      .infoTitle {
        display: none;
      }
      .infomsg {
        margin: 0rem 0.16rem;
        border-bottom: 0.01rem solid #eeeeee;
        padding: 0.24rem 0;
      }
      .title {
        z-index: 9;
        margin: 0;
        padding: 0;
        width: auto;
        // margin: .24rem 0;
        font-size: 0.14rem;
        background: #ffffff;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 0.22rem;
      }
      .info {
        z-index: 9;
        margin: 0;
        padding: 0;
        width: auto;
        background: #ffffff;
        ul {
          margin-top: 0.24rem;
          margin-bottom: 0.24rem;
          li {
            font-size: 0.14rem;

            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 0.22rem;
            margin-bottom: 0.08rem;
          }
        }
      }
    }
    .brief-introduction::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;

      background: rgba(0, 0, 0, 0.65);
      width: 100vw;
      height: 100vh;
    }
    .pagination {
      position: relative;
      bottom: 0;
      left: 0;
      display: none;
      input {
        width: 0.28rem;
        height: 0.28rem;
        background: #ffffff;
        border-radius: 0.04rem;
        border: 0.01rem solid #eeeeee;
      }
      .to {
        display: block !important;
        width: 0.5rem;
        height: 0.28rem;
        background: #ffffff;
        border-radius: 0.04rem;
        border: 0.01rem solid #eeeeee;
      }
      .go {
        margin: 0 0.08rem 0 0;

        font-size: 0.12rem;
      }
      .page {
        margin: 0 0.08rem;

        font-size: 0.12rem;
      }
      /deep/ .el-pagination.is-background .btn-prev {
        width: 0.5rem;
        height: 0.28rem;
        text-align: center;
      }
      /deep/ .el-pagination.is-background .btn-next {
        width: 0.5rem;
        height: 0.28rem;
        text-align: center;
      }
      /deep/ .el-pagination.is-background .el-pager li {
        width: 0.28rem;
        height: 0.28rem;
        line-height: 0.28rem !important;
        font-size: 0.12rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 0.19rem;
      }
      /deep/.btn-prev {
        width: 0.5rem;
        height: 0.28rem;
        text-align: center;
        background: #ffffff;
        border-radius: 0.04rem;
        border: 0.01rem solid #eeeeee;
        i {
          font-size: 0.12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 0.19rem;
        }
        .el-icon-arrow-left::before {
          font-size: 0.12rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 0.19rem;
        }
      }
      /deep/.el-button--default {
        display: none;
      }
    }

    .banner {
      display: none;
    }
  }
  /deep/ .el-icon-arrow-left::before {
    content: '\e6de';
    color: var(--backgroundColor);
    font-size: 0.2rem;
    font-weight: 600;
  }
  .pickerOut {
    position: fixed;
    top: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
  }
  .picker {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    /deep/ .van-picker__toolbar {
      border-bottom: 1px solid #eeeeee;
      height: 0.56rem;
      font-size: 0.16rem;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.24rem;
    }
    /deep/.van-picker__title {
      font-size: 0.16rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      line-height: 0.24rem;
    }
    /deep/.van-picker-column__item--selected {
      font-size: 0.2rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.28rem;
    }
    /deep/ .van-picker__confirm {
      color: var(--backgroundColor);
    }
  }
  .index-body {
  position: relative;
  .banner_info {
    position: absolute;
    right: 0;
    top: 0;
    li {
      width: 1.25rem;
      height: 0.44rem;
      background: #f6f6f6;
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.44rem;
      text-align: center;
      border-top: 1px solid #ffffff;
    }
    .active {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.titlekb{
    width: 100%;
    height: 100vh;
    line-height: 80vh;
    font-size: 0.14rem;
 text-align: center;
font-weight: 400;
color: rgba(0,0,0,0.85);
background: #ffffff;
border-bottom: 1px solid #eeeeee;
 

}
}
</style>
