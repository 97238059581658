<template>
  <div class="search" :class="workName  ? 'searchActive' :''">
      <div class="BlockIcon" v-show="bookssize+coverMediaTotal+InfoTotal1+picInfoTotal1<=0">
      <div class="title">暂无内容,试试其他关键词</div>
 
    </div>
    <el-tabs v-show="bookssize+coverMediaTotal+InfoTotal1+picInfoTotal1>0" v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="`图书(${bookssize})`" name="first">
        <Books  @booksall="booksall"/>
      </el-tab-pane>
      <el-tab-pane  :label="`融媒体(${coverMediaTotal})`" name="second">
        <coverMedia @coverMedia="coverMedia" />
      </el-tab-pane>
      <el-tab-pane    :label="`资讯（${InfoTotal1 + picInfoTotal1}）`" class="open" name="third">
        <Information @InfoTotal="InfoTotal" @picInfoTotal="picInfoTotal"  @workNameChange='workNameChange' :workName="workName"/>
      </el-tab-pane>
    </el-tabs>
  
  </div>
</template>

<script>
import Books from "./about/books.vue";
import coverMedia from "./about/coverMedia.vue";
import Information from "./about/information.vue";
export default {
  data() {
    return {
      activeName: "first",
      blockNone:true,
      list:['党史','长征'],
      active:'',
      workName:'',
      coverMediaTotal:1,
      picInfoTotal1:1,
      InfoTotal1:1,
      bookssize:1
    };
  },
  components: {
    Books,
    Information,
    coverMedia,
  },
  created() {
    console.log(this.$route.query,'router');
    // if(this.$route.query('') =="searchMobile"){

    // }
      // if(this.$route.query.keyword){
      //       this.blockNone = true
      //   }else {
      //        this.blockNone = false
      //    }
   },

  methods: {
    handleClick(tab, event) {
      
      console.log(tab.name, event);
      if(tab.name == 'third'){
          console.log('third');
          if(this.workName == 'third'){
              this.workName = 'other'
              this.activeName = ''

          }else {
              this.workName = tab.name
           }


      }else {
        this.workName = ''
       }
    },
    booksall(val){
      this.bookssize = val
      console.log( this.bookssize,' this.bookssize');
    },
    coverMedia(val){
      this.coverMediaTotal = val
      console.log( this.coverMediaTotal,' this.coverMediaTotal');

    },
    InfoTotal(val){
        this.InfoTotal1 = val
        console.log( this.InfoTotal1,' this.InfoTotal1');
    },
    picInfoTotal(val){
      this.picInfoTotal1 =  val
       console.log( this.InfoTotal1,' this.picInfoTotal1');
    },
    workNameChange(key){
      console.log(key,'key');
    
        //  if(key == 'other'){
           this.activeName = ''
            this.workName = key
        //  }
    },
    selectKeyword(key){
        this.active = key
        console.log(this.active == key);
    },
    open(){
      console.log(111);
    },
  },
  watch: {
    $route(to, from) {
        console.log(to,from,'route');
        if(to.query.keyword){
            this.blockNone = true
        }else {
             this.blockNone = false
         }

    
    },
  },
};
</script>

<style lang="scss" scoped>
.BlockIcon{

    background: #ffffff;
}
 /deep/ #tab-third{
  &::after{
    // margin-left: ;
    position: relative;
    display: inline-block;
    content: '';
    width: .13rem;
    height: .08rem;
    transform: rotateX(180deg);
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAQCAYAAAAI0W+oAAAAAXNSR0IArs4c6QAAAWlJREFUOE+90mlOwzAQBeD3Rr0Eh4pzEXZo2fcdys5F4hyKS1TzEIE6AUKbCgn/szzzPm/EPw3O6pSwAzdj7qPDWXpngiJsH+RRBUgHAX7cFesMFbA9kl+CJe3n8JMuWCeohO2KbA2ktJfBT6dhU6EI2wGZgijNVzdHvqRwaTfAzyZhE6EI2waZAiQt5PAKKGDzJJ8b2E6An/+G/QoVsC2SdaO0GOB1MIAIWwD5NA6XtJ3DL9qwVijCNkHWDS3IOKyELYp8bJxsK8Avv2M/oAjbAJkKKS1l8LTrtt22YJsBftWs/QIVsAHJVCBpOYfXu53w2gVsieRD4xo3cvj1eJ6gEtYXmRYgrQR4apz2fd/XI2wZ5H0KlwYZ/OZ9XkEtyGqAp4YuyLgmwlZA3jXerB/gwwqK1ruBtF7J0loGrwtnUT5rS9iqyNtqSg6Dj/r11Vlv6O6vOfyj4I+jgK2Z2Vzmo48D/DGvc/sbUl2YEd+bl04AAAAASUVORK5CYII=') ;
    background-repeat: no-repeat; 
     background-size: auto 100%;
  }
}
.searchActive{
   /deep/ #tab-third{
    color: #c3282b;
  }
  /deep/  #pane-third{
  display: block !important;
 

}

} 
 
.title{
    width: 100%;
    height: 1.02rem;
    line-height: 1.02rem;
    font-size: 0.14rem;
 text-align: center;
font-weight: 400;
color: rgba(0,0,0,0.85);
background: #ffffff;
border-bottom: 1px solid #eeeeee;
 

}
.searchIcon{
    margin-top: .32rem;
    display: flex;  
    justify-content: space-between; 
height: 0.24rem;
margin-bottom: .19rem;
span{
    margin-left: .16rem;
    font-size: 0.16rem;
 
font-weight: 500;
color: rgba(0,0,0,0.85);
line-height: 0.24rem;
}
figure{
    width: 0.18rem;
    height: .18rem;
    margin-right: .16rem;
    img{
        width: 100%;
        height: 100%;
    }
}

}
.icons{
    display: flex;
    flex-wrap: wrap;
    margin-left: .16rem;
    padding-bottom: 1rem;
    li{
        width: 0.7rem;
height: 0.28rem;
background: #EEEEEE;
border-radius: 0.04rem;
margin-right: .08rem;
font-size: 0.12rem;
 text-align: center;
 
font-weight: 400;

color: rgba(0,0,0,0.45);
line-height: 0.28rem;
margin-bottom: .08rem;
    }
    .active{
        background: #FFF4F4;
border-radius: 0.04rem;
border: 0.01rem solid #C3282B;

color: #C3282B;
    }
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  width: 1.24rem;
  height: 0.44rem;
  background: #f6f6f6;
  border-left: 1px solid #ffffff;
  border-bottom: none;
  font-size: 0.14rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: #c3282b;
  &::after{
   transform: rotateX(0deg) !important ;
  }
  
}
/deep/.el-tabs__content {
  background: #ffffff;
}
/deep/.el-tabs__header {
  margin: 0 !important;
}
</style>
 