<template>
  <div class="index-body">
    <div class="banner">
      <span>融媒体</span>
    </div>
    <div class="brief-introduction" ref="Main4">
      <ul v-if="dataList.length > 0 ">
        <li
          class="media"
          @click="goDetail(item.id)"
          v-for="item in dataList"
          :key="item.id"
        >
          <figure>
            <img :src="item.picUrl || picUrlcheck(item.fileUrl ,item)" alt="" />
          </figure>
          <div class="title">
            <div v-html="keySign(item.title)"></div> 
            <div class="content"> 
              <div v-html="keySign(decode(item.content))"></div>
              <!-- <span v-if="decode(item.content).length > 15" class="check">查看</span> -->
            </div>
            
          </div>
        </li>
      </ul>
   
       <div  v-if="dataList.length <= 0 " class="titlekb">暂无结果，请尝试其他关键词</div>
      <div class="pagination" ref="bottom">
        <el-button :disabled="dataFrom.firstPageDisabled" @click="goPageOne()">
          首页</el-button
        >
        <el-pagination
          :pager-count="5"
          background
          :current-page="dataFrom.page"
          :page-size="dataFrom.limit"
          layout="prev, pager, next"
          :total="total"
          @current-change="pageCurrentChangeHandle"
        >
        </el-pagination>
        <el-button :disabled="dataFrom.lastPageDisabled" @click="goPageLast()"
          >末页</el-button
        >
        <span class="go">跳转</span>

        <input :value="goPage" @input="handleInput" type="text" />
        <span class="page">页</span>
        <el-button @click="go()">跳转</el-button>
      </div>
    </div>
  </div>
</template>

 
<script>
import Swiper from "swiper";
import music from '../../../assets/home/music.png'
export default {
  name: "vue-index",

  data() {
    return {
      msg: "",
      show: false,
      goPage: "",

      dataFrom: {
        page: 1,
        limit: 10,
        firstPageDisabled: false,
        lastPageDisabled: false,
        total: 0,
        status: 1,
      },
      dataList: [],
      total: 0,
      page: 1,
      allTotal: 0,
      importTotal: 0,
      newTotal: 0,
      dataFormPages: [],
      music:music
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      // // 如果需要分页器
      // pagination: '.swiper-pagination',
      // // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    });

    this.query();
  },
  created() {
    this.scrollWindow();
  },
  methods: {
    handleInput(value) {
      this.goPage = +value.data;
    },
      keySign(title) {
      let s = this.$route.query.keyword; // 搜索框的值(您要标红的关键字)
      var str = title; // 列表标题(原文本)

      // 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(new RegExp(/\s+/g), ","); // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); // 把字符串分割转换成数组(方便截取)
      // 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g");
          // 如果匹配成功则抛出关键字DOM
          // TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(
            reg,
            '<i style="color:red;font-weight: bold;">' + e + "</i>"
          ); // 改变搜索关键字颜色为红色
        });
      }
      return str;
    },
    go() {
      this.dataFrom.page = this.goPage;
      this.query();
    },
      decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      var test = base64 && base64.replaceAll(/<[^>]+>/g, "");
      return test;
    },
    goPageOne() {
      this.dataFrom.page = 1;
      this.query();
    },
    goPageLast() {
      this.dataFrom.page = Math.ceil(this.dataFrom.total / this.dataFrom.limit);
      this.query();
    },
    goDetail(id) {
      this.$router.push({ path: "/convergencemedia/media", query: { id: id } });
    },
    selectType(status) {
      this.dataFrom.status = status;
      this.query();
    },
    select(type) {
      this.dataFrom.type = type;
      this.query();
    },
        picUrlcheck(Url,item){
      let URL = JSON.parse(Url)[0]
      let urlcheck =  URL.response.substring(URL.response.lastIndexOf(".") + 1)
      if(urlcheck == 'mp3'){
          return this.music
      }else{
        return item.firstFramePicUrl
      }
    },
    pageCurrentChangeHandle(index) {
      this.dataFrom.page = index;
      this.query();
    },
    query() {
      console.log(this.dataFrom, "resdata");

      this.$http
        .get("/convergencemedia/homePage", {
          params: {
            ...this.dataFrom,
            keyword: this.$route.query.keyword
          },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.dataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.dataList = res.data.list;
          this.dataList.forEach(async (e) => {
            if (!e.picUrl) {
              e.picUrl = await this.getVideoBase64();
            }
          });
          this.total = res.data.total;
          this.$emit('coverMedia',res.data.total)
          this.dataFrom.total = res.data.total;
        })
        .catch(() => {});
    },
    getVideoBase64(url) {
      return new Promise(function (resolve) {
        let dataURL = "";
        let video = document.createElement("video");
        video.setAttribute("crossOrigin", "anonymous"); //处理跨域
        video.setAttribute("src", url);
        video.setAttribute("width", 400);
        video.setAttribute("height", 240);
        video.setAttribute("preload", "auto");
        video.addEventListener("loadeddata", function () {
          let canvas = document.createElement("canvas"),
            width = video.width, //canvas的尺寸和图片一样
            height = video.height;
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
          dataURL = canvas.toDataURL("image/jpeg"); //转换为base64
          resolve(dataURL);
        });
      });
    },
    scrollWindow() {
      window.addEventListener("scroll", () => {
        if (
          navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
          )
        ) {
          //显示移动端的代码
          this.getMainBox();
        } else {
          console.log("11");
        }
      });
    },
    async getMainBox() {
      let Main = this.$refs.Main4;
      let bottom = this.$refs.bottom; //底部的相关DOM
      // console.log(Main.offsetTop);      //父盒子距离浏览器顶部的高度
      // console.log(window.innerHeight);  //浏览器的高度，即页面窗口的高度
      if (!Main && !Main.clientHeight) {
        return;
      } //父盒子的高度(加上padding和margin)
      console.log(window.scrollY); //浏览器右边的滚动条滚动距离
      if (
        Math.abs(
          Main.clientHeight -
            window.innerHeight -
            (window.scrollY - Main.offsetTop - bottom.clientHeight)
        ) <= 10
      ) {
        console.log("我滑动到底部了");
        this.dataFrom.page += 1;
        let { data: res } = await this.$http.get("/convergencemedia/homePage", {
          params: {
            ...this.dataFrom,
                 keyword: this.$route.query.keyword
          },
        });
        this.dataList = this.dataList.concat(res.data.list);
        console.log(this.dataList, res.data.list, "this.dataLis");

        // alert('12323132')
        //这里在运用在获取新的相关数据即可
      }
    },
  },
  watch: {
    dataFrom: {
      handler(newVal) {
        let pages = Math.ceil(this.total / newVal.limit);
        this.goPage = this.dataFrom.page;
        if (pages === 0) {
          // 数据(totalResult)为0
          this.dataFrom.firstPageDisabled = true; // 首页按钮是否禁用
          this.dataFrom.lastPageDisabled = true; // 末页按钮是否禁用
        } else {
          this.dataFrom.firstPageDisabled = newVal.page === 1;
          this.dataFrom.lastPageDisabled = newVal.page === pages;
          console.log(
            pages,
            this.dataFrom.firstPageDisabled,
            this.dataFrom.lastPageDisabled,
            "pages"
          );
        }
      },
      // 一进页面就执行
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 0.56rem;
  height: 3.3rem;
  background: linear-gradient(180deg, #b20103 0%, #f8f8f8 100%);

  span {
    position: relative;
    left: 0.84rem;
    top: 0.64rem;
    font-size: 0.48rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.56rem;
  }
}
.media {
  cursor: pointer;
}

/deep/ .el-icon-arrow-down:before {
  color: var(--backgroundColor);
}
/deep/ .el-menu-item {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.48rem;
  line-height: 0.48rem;
  padding-left: 0.64rem !important;
  position: relative;
  margin-bottom: 0.08rem;
}
/deep/ .el-submenu__title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.56rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
}
/deep/.el-menu-item:hover {
  background: #eeeeee;
}
/deep/.el-submenu__title:hover {
  background: #ffffff;
}
/deep/ .el-menu-item.is-active {
  background: rgba(195, 40, 43, 0.1);
}

.el-menu-item.is-active::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  width: 0.04rem;
  height: 0.48rem;
  background: #c3282b;
}

.pagination {
  position: absolute;
  right: 0.32rem;
  //   margin-top: 0.16rem;
  bottom: 0.32rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin: 0 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    text-align: center;
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  input:focus-visible {
    outline: none;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  // /deep/ .el-input__inner {
  //   width: 0.32rem;
  //   height: 0.32rem;
  //   background: #ffffff;
  //   border-radius: 0.04rem;
  //   border: 0.01rem solid #eeeeee;
  // }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  /deep/ .el-pagination .btn-prev .el-icon {
    content: "首页";
      font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
      content: "上一页";                font-weight: 500;
         font-size: .14rem;
  }
  /deep/ .el-icon-arrow-right::before {
        content: "下一页";            font-weight: 500;
         font-size: .14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
  }
  /deep/.el-button span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
  }
  /deep/ .el-button.is-disabled span {
    color: #c0c4cc;
  }
  .el-pagination .btn-prev .el-icon {
    content: "首页";
      font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
      content: "上一页";                font-weight: 500;
         font-size: .14rem;
  }
  /deep/ .el-icon-arrow-right::before {
        content: "下一页";            font-weight: 500;
         font-size: .14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
    color: #ffffff;
  }
}

.brief-introduction {
  position: relative;
  top: -1.2rem;
  box-sizing: border-box;
  margin: 0 0.64rem;
  width: 13.1rem;

  height: 6.08rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  // margin-bottom: 0.16rem;
  margin-bottom: -0.88rem;

  padding: 0.4rem;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 0.2rem;

      figure {
        width: 2.3rem;
        height: 1.48rem;
        margin-bottom: 0.16rem;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }
      figure::after {
        position: absolute;
        left: 50%;
        top: 50%;
        content: "";
        width: 0.23rem;
        height: 0.23rem;
        background: url("../../../assets/recommend/mediaIcon.png") no-repeat center;
        background-size: 100% 100%;
        transform: translate(-50%, -50%);
      }
      .title {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
        overflow-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        overflow: hidden;
        font-size: 0.16rem;
        width: 2.3rem;
        height: 0.48rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 0.24rem;
        margin-bottom: 0.32rem;
      }
    }
    li:nth-child(5n) {
      margin-right: 0;
    }
  }
}

@media (max-width: 768px) {
  .index-body {
    margin-top: 0;

    padding-left: 0;
    background: #ffffff;

    .mian-body {
      top: 0;
      padding: 0;
      margin-bottom: 0;
      .container {
        padding: 0;
        width: 3.75rem;
        .button {
          width: 3.75rem;
          height: 0.56rem;
          background: #ffffff;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          div {
            background: #ffffff;
            width: 0.96rem;
            justify-content: flex-end;
            span {
              font-size: 0.14rem;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .select {
            display: flex;
            flex-grow: 1;
            justify-content: flex-start;
            margin-left: 0.16rem;
            span {
              margin-left: 0.08rem;
            }
            svg {
              fill: var(--backgroundColor);
            }
          }
          .time {
            margin-right: 0.22rem;
          }
          svg {
            width: 100 !important;
            height: 100 !important;
          }
        }
        .books {
          width: 100%;
          li {
            margin: 0;
            margin-bottom: 0.14rem;
            margin-left: 0.15rem;
            figure {
              width: 0.88rem;
              height: 1.22rem;
            }
            .books-msg {
              span:nth-child(1) {
                margin: 0;
                font-size: 0.16rem;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
                line-height: 0.24rem;
                margin-bottom: 0.1rem;
              }
              span:nth-child(2) {
                margin: 0;
                i {
                  display: inline-block;
                  padding: 0 0.08rem;
                  height: 0.24rem;
                  line-height: 0.24rem;

                  background: rgba(255, 244, 244, 1);
                  border-radius: 0.02rem;
                  color: rgba(195, 40, 43, 1);
                }
                i:nth-child(1) {
                  // margin-right: .02rem;
                  background: #f3e3e3;
                }
              }
              span:nth-child(3) {
                display: none;
              }
              span:nth-child(4) {
                margin-top: 0.12rem;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                line-height: 0.22rem;
                width: 2.37rem;

                height: 0.4rem;
                font-size: 0.13rem;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
      .sidebar {
        display: none;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;

        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        .sidebarInner {
          width: 3rem;
          height: 100vh;
          background: #ffffff;
          border-radius: 0rem 0.16rem 0.16rem 0rem;
          overflow: hidden;
        }
        .sidebarTop {
          margin-top: 0.6rem;
          margin-bottom: 0.07rem;
          margin-left: 0.3rem;
          display: flex;
          span {
            width: 0.64rem;
            height: 0.24rem;
            font-size: 0.16rem;

            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            line-height: 0.24rem;
            margin-left: 0.62rem;
          }
        }

        .buttons {
          margin: 0 0.56rem;
          margin-top: 0.32rem;
          display: flex;
          justify-content: space-between;
          .reset {
            width: 0.86rem;
            height: 0.32rem;
            background: #fff4f4;
            border-radius: 0.04rem;

            font-size: 0.14rem;

            font-weight: 400;
            color: #c3282b;
            line-height: 0.32rem;
            text-align: center;
          }
          .confirm {
            width: 0.86rem;
            height: 0.32rem;
            background: #c3282b;
            border-radius: 0.04rem;
            text-align: center;
            font-size: 0.14rem;

            font-weight: 400;
            color: #ffffff;
            line-height: 0.32rem;
          }
        }
      }
    }

    .banner {
      display: none;
    }
  }
  /deep/ .el-icon-arrow-left::before {
    content: "\e6de";
    color: var(--backgroundColor);
    font-size: 0.2rem;
    font-weight: 600;
  }
  .brief-introduction {
    margin: 0;
    padding: 0;
    top: 0;
    width: auto;
    height: auto;
    ul {
      padding: 0.16rem;
      justify-content: space-between;
      padding-bottom: 0.36rem;
      li {
        margin-right: 0;
        display: flex;
        figure {
         width: 1.13rem;
height: 0.72rem;
         margin-right: .12rem;
        }
        .title {
          width: 2.18rem;
          max-height: 0.44rem;
          font-size: 0.14rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.9);
          .content{
              width: 2rem;
         
              font-size: 0.13rem;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: rgba(0,0,0,0.65);
              line-height: 0.2rem;
                .check{
                  position: absolute;
                  right: 0;
                  color: #B20103;
                }
          }

        }
      }
    }
    .pagination {
      display: none;
    }
  }
.titlekb{
    width: 100%;
    height: 100vh;
    line-height: 80vh;
    font-size: 0.14rem;
 text-align: center;
font-weight: 400;
color: rgba(0,0,0,0.85);
background: #ffffff;
border-bottom: 1px solid #eeeeee;
 

}
}

</style>