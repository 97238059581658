<template>
  <div class="index-body">
    <div class="banner">
      <span>图书推荐</span>
      <!-- <img src="../../assets/recommend/banner.png" alt="" /> -->
    </div>
    <div class="mian-body">
      <div class="sidebar"    @click.prevent="closeSidebar" :style="{ display: show ? 'block' : '' }">
        <div class="sidebarInner"  @click="preventdefault($event)">
          <div class="sidebarTop">
            <i class="el-icon-arrow-left" @click="goback"></i>
            <span>图书分类</span>
          </div>
          <el-menu
            class="el-menu-vertical-demo"
            @open="selectAll"
            unique-opened
            :default-active="'3'"
            :default-openeds="['3']"

          >
            <!-- <el-menu-item
              class="topone"
              :class="dataFrom.publishRange > 2 ? 'is-active' : ' '"
              index="3"
              @click="select('', 3)"
            >
              <span>全部（{{ sideMsg.allsize }})</span>
                <el-menu-item-group>
                <el-menu-item
                  :index="'0-' + item.id"
                  v-for="item in sideMsg.directory"
                  @click="select(item.id,3)"
                  :key="item.id"
                  >{{ item.categoryName }}（{{ item.num }}）</el-menu-item
                >
              </el-menu-item-group>
            </el-menu-item> -->
            <!-- <el-submenu index="0">
              <template slot="title">
                <span>长销图书({{ sideMsg.recommendedsize }})</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'0-' + item.id"
                  v-for="item in sideMsg.recommended"
                  @click="select(item.id, 0)"
                  :key="item.id"
                  >{{ item.categoryName }}（{{ item.num }}）</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu> -->
            <!-- <el-submenu index="1">
              <template slot="title">
                <span>新书出版({{ sideMsg.publishsize }})</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'1-' + item.id"
                  v-for="item in sideMsg.publish"
                  @click="select(item.id, 1)"
                  :key="item.id"
                  >{{ item.categoryName }}（{{ item.num }}）</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu> -->
            <!-- <el-submenu index="2">
              <template slot="title">
                <span> 系列专题({{ size }})</span>
              </template>
                  <el-menu-item-group>
                <el-menu-item
                  :index="'2-' + item.id"
                  v-for="item in sideMsg.publish"
                  @click="selectSeries(item.id, 1)"
                  :key="item.id"
                  >{{ item.categoryName }}（{{ item.num }}）</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu> -->
            <el-submenu index="3">
              <template slot="title">
                <span>图书({{ sideMsg.allsize }})</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'0-' + item.id"
                  v-for="item in sideMsg.directory"
                  @click="select(item.id)"
                  :key="item.id"
                  >{{ item.categoryName }}（{{ item.num }}）</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>

          <div class="buttons">
            <div class="reset" @click="reset">返回</div>
            <div class="confirm" @click="confirm">确认</div>
          </div>
        </div>
      </div>
      <div class="container"  v-show="dataList.length > 0 ">
        <section class="button" v-show="defaultOpends != 2">
          <div class="select" @click="showSelect">
            <section class="icon">
              <svg
                t="1685339716433"
                class="iconselect"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="6967"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                :width="'.24rem'"
                :height="'.24rem'"
              >
                <path
                  d="M855.342766 151.46262c-6.056949-11.851932-18.984377-19.393699-33.219589-19.393699L101.903901 132.068921c-14.360056 0-27.412326 7.704472-33.396621 19.744693-5.988388 12.015661-3.845585 26.157753 5.520737 36.192294l255.896134 274.308483 0 309.339324c0 12.847609 7.895831 24.602328 20.389376 30.328749l189.116195 86.432535c5.154393 2.371 10.771321 3.515057 16.33913 3.515057 6.541997 0 13.090133-1.607614 18.926048-4.797259 10.718109-5.945409 17.427928-16.503882 17.809621-28.037567l12.957103-396.767536 245.078765-274.90507C859.543438 177.316451 861.425298 163.313529 855.342766 151.46262zM520.773827 804.275693l-117.384477-53.647851L403.38935 483.628836l127.858016 0L520.773827 804.275693zM550.774095 416.986019c-1.963725-0.299829-3.761674-1.090844-5.809309-1.090844L383.519814 415.895175 181.938726 199.803605l562.427506 0L550.774095 416.986019zM685.454494 524.008498l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 654.104485l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 773.618463l273.392624 0 0 59.759035-273.392624 0 0-59.759035Z"
                  p-id="6968"
                ></path>
              </svg>
            </section>
            <span>{{ name }} </span>
          </div>
          <div class="attention" @click="attentionfocus">
            <span>关注度</span>
            <section class="icon">
              <i class="up" :class="attentionborderColor"></i>
              <i class="down" :class="attentionDownborderColor"></i>
            </section>
          </div>
          <div class="time" @click="publishTimefocus">
            <span>出版时间</span>
            <section class="icon">
              <i class="up" :class="publishTimeborderColor"></i>
              <i class="down" :class="publishTimeDownborderColor"></i>
            </section>
          </div>
        </section>
        <ul class="books" ref="Main7">
          <li
            v-for="item in dataList"
            class="book"
            :key="item.id"
            @click="goDetail(item.id)"
          >
            <figure>
              <img :src="item.coverUrl" alt="" />
            </figure>
            <div class="books-msg">
              <span v-html="keySign(item.bookName)"></span>
              <span v-show="defaultOpends != 2">
                <i  v-html="authorkeySign(item.author)"></i> <i>价格:{{ item.price }}</i>
              </span>
              <span :class="defaultOpends != 2 ? '' : 'xilie'">{{
                defaultOpends != 2 ? "图书简介" : "系列专题"
              }}</span>
              <span v-html="keySign(decode(item.contentIntro)) "></span>
                  <span
                class="buyiconyidong"
                @click.stop="buy(item.purchaseLink)"
                v-if="item.purchaseLink"
              >
                点击购买
              </span>
            </div>
          </li>
          <!-- <span v-if="loading"></span> -->
        </ul>

        <div class="pagination" ref="bottom">
          <el-button :disabled="dataFrom.firstPageDisabled"> 首页</el-button>
          <el-pagination
            :pager-count="5"
            background
            :current-page="dataFrom.page"
            :page-size="dataFrom.limit"
            layout="prev, pager, next"
            :total="total"
            @current-change="pageCurrentChangeHandle"
          >
          </el-pagination>
          <el-button :disabled="dataFrom.lastPageDisabled">末页</el-button>
          <span class="go">跳转</span>

          <input :value="goPage" @input="handleInput" type="text" />
          <span class="page">页</span>
          <el-button @click="go()">跳转</el-button>
        </div>
      </div>
       <div  v-if="dataList.length <= 0 " class="titlekb">暂无结果，请尝试其他关键词</div>
    </div>
  </div>
</template>

 
<script>
import Swiper from "swiper";
export default {
  name: "vue-index",

  data() {
    return {
      msg: "",
      show: false,
      dataFrom: {
        page: 1,
        limit: 4,
        orderField: "", //排序字段
        firstPageDisabled: false,
        lastPageDisabled: false,
        total: 0,
        order: "",
        status: 1,
      },
      defaultOpends: 3,
      dataList: [],
      total: 0,
      page: 1,
      allTotal: 0,
      importTotal: 0,
      newTotal: 0,
      dataFormPages: [],
      goPage: 0,
      sideMsg: {},
      name: "全部",
      loading: false,
      size: 0,
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      // // 如果需要分页器
      // pagination: '.swiper-pagination',
      // // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    });
  },
  async created() {
    this.dataFrom.keyword = this.$route.query.keyword;
    // this.defaultOpends = this.$route.query.publishRange;
    this.dataFrom.publishRange = this.$route.query.publishRange;
    console.log(this.dataFrom.publishRange, "   this.dataFrom.publishRange");
    if (!this.dataFrom.publishRange) {
      this.dataFrom.publishRange = 3;
    }
    console.log(this.defaultOpends, "this.defaultOpends");
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      //显示移动端的代码
      this.dataFrom.limit = 10;
    }

    await this.get();
    // this.getInfo();
    this.getSide();
    this.scrollWindow();

    this.allTotal = this.total;
  },
  computed: {
    publishTimeborderColor: function () {
      console.log("1111");
      if (
        this.dataFrom.orderField == "publication_time" &&
        this.dataFrom.order == "asc" &&
        this.dataFrom.order !== ""
      ) {
        return "up_active";
      } else {
        return "";
      }
    },
    // name: function () {
    //   if (this.dataFrom.publishRange == 0) {
    //     return "长销图书";
    //   } else if (this.dataFrom.publishRange == 1) {
    //     return "新书出版";
    //   } else if (this.dataFrom.publishRange == 2) {
    //     return "系列专题";
    //   } else {
    //     return "全部";
    //   }
    // },

    publishTimeDownborderColor: function () {
      console.log(222);
      if (
        this.dataFrom.orderField == "publication_time" &&
        this.dataFrom.order == "desc" &&
        this.dataFrom.order !== ""
      ) {
        return "down_active";
      } else {
        return "";
      }
    },
    attentionDownborderColor: function () {
      console.log(222);
      if (
        this.dataFrom.orderField == "page_view" &&
        this.dataFrom.order == "desc" &&
        this.dataFrom.order !== ""
      ) {
        return "down_active";
      } else {
        return "";
      }
    },
    attentionborderColor: function () {
      console.log(222);
      if (
        this.dataFrom.orderField == "page_view" &&
        this.dataFrom.order == "asc" &&
        this.dataFrom.order !== ""
      ) {
        return "up_active";
      } else {
        return "";
      }
    },
  },
  methods: {
    publishTimefocus() {
      if (this.dataFrom.orderField !== "publication_time") {
        this.dataFrom.orderField = "publication_time";
        this.dataFrom.order = "";
      }

      if (this.dataFrom.order == "asc") {
        this.dataFrom.order = "desc";
      } else if (this.dataFrom.order == "desc") {
        this.dataFrom.order = "";
      } else {
        this.dataFrom.order = "asc";
      }
      this.query();
      console.log(this.dataFrom.order);
    },
        closeSidebar() {
      this.show = !this.show;
    },
       buy(url) {
      // window.location.href = url;
      if (!url) {
        return;
      }
      window.open(url);
    },
    preventdefault(event) {
      console.log("zuzhizuzhi");
      event.stopPropagation();
    },
    authorkeySign(title){
      let s = this.$route.query.keyword; // 搜索框的值(您要标红的关键字)
      var str = title; // 列表标题(原文本)

      // 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(new RegExp(/\s+/g), ","); // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); // 把字符串分割转换成数组(方便截取)
      // 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g");
          // 如果匹配成功则抛出关键字DOM
          // TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(
            reg,
            '<i style="color:red;font-weight: bold;">' + e + "</i>"
          ); // 改变搜索关键字颜色为红色
        });
      }
      return '作者：' + str;
    },
       keySign(title) {
      let s = this.$route.query.keyword; // 搜索框的值(您要标红的关键字)
      var str = title; // 列表标题(原文本)

      // 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(new RegExp(/\s+/g), ","); // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); // 把字符串分割转换成数组(方便截取)
      // 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g");
          // 如果匹配成功则抛出关键字DOM
          // TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(
            reg,
            '<i style="color:red;font-weight: bold;">' + e + "</i>"
          ); // 改变搜索关键字颜色为红色
        });
      }
      return str;
    },

    attentionfocus() {
      if (this.dataFrom.orderField !== "page_view") {
        this.dataFrom.orderField = "page_view";
        this.dataFrom.order = "";
      }

      if (this.dataFrom.order == "asc") {
        this.dataFrom.order = "desc";
      } else if (this.dataFrom.order == "desc") {
        this.dataFrom.order = "";
      } else {
        this.dataFrom.order = "asc";
      }
      this.query();
    },
    goDetail(id) {
      if (this.defaultOpends == 2 || this.dataFrom.publishRange == 2) {
        this.$router.push({
          path: "recommend/seriesdetail",
          query: {
            id: id,
            publicRange: 2,
          },
        });
      } else {
        this.$router.push({
          path: "recommend/bookdetail",
          query: {
            id: id,
            publicRange: this.dataFrom.publishRange,
          },
        });
      }
    },
    selectAll(msg) {
      console.log(msg, "msgggg");
      this.defaultOpends = msg;
      this.dataFrom.publishRange = msg;
      console.log(msg);
      this.get();
    },
    //   selectSeries(index) {
    //   this.defaultOpends = [index];
    //   console.log(index);
    //   this.get();
    // },
    async getSide() {
      let { data: res } = await this.$http.get(
        "/books/getAllBooksInfoByConditionKeyword",
        {
          params: { status: 1, keyword: this.$route.query.keyword },
        }
      );

      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }
      this.$emit("booksall", res.data.allsize);
      this.sideMsg = res.data;
    },
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      var test = base64 && base64.replaceAll(/<[^>]+>/g, "");
      return test;
    },
    showSelect() {
      this.show = !this.show;
    },

    handleInput(value) {
      this.goPage = +value.data;
    },
    go() {
      this.dataFrom.page = this.goPage;
      this.get();
    },
    goback() {
      this.show = !this.show;
    },
    reset() {
      // this.dataFrom = {
      //   page: 1,
      //   limit: 4,
      //   orderField: "", //排序字段
      //   firstPageDisabled: false,
      //   lastPageDisabled: false,
      //   total: 0,
      //   order: "",
      //   status: 1,
      // };
      // this.get();
      this.show = !this.show;
    },

    confirm() {
      // this.dataFrom.category 

      let selectName =  this.sideMsg.directory.filter( e => e.id === this.dataFrom.category )
  console.log(selectName,'selectName');
  this.name = selectName[0].categoryName

      this.get();

      this.show = !this.show;
    },
    selectType(status) {
      console.log("status", status);
      if (status) {
        this.dataFrom.publishRange = status;
      } else {
        this.dataFrom.publishRange = null;
      }

      this.get();
    },
    select(type, publishRange) {
      this.dataFrom.category = type;
      this.dataFrom.publishRange = publishRange;
      this.dataFrom.page = 1;
      // this.defaultOpends = [publishRange]
      console.log(this.dataFrom);

      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        //显示移动端的代码
      } else {
        this.get();
      }
    },
    pageCurrentChangeHandle(index) {
      this.dataFrom.page = index;
      this.get();
    },

    get() {
      console.log(this.defaultOpends, "defaultOpends");
      if (this.defaultOpends == 2) {
        this.querySeries();
      } else {
        this.query();
      }
    },
    async query() {
      let publishRange =
        this.dataFrom.publishRange == 3 ? null : this.dataFrom.publishRange;
      let { data: res } = await this.$http.get("/books/homePage", {
        params: {
          ...this.dataFrom,
          publishRange: publishRange,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;
      this.total = res.data.total;
      this.dataFrom.total = res.data.total;
    },
    async querySeries() {
      let { data: res } = await this.$http.get("/seriessubject/homePage", {
        params: {
          ...this.dataFrom,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;
      this.total = res.data.total;
      this.size = res.data.total;
      console.log(this.dataList, "this.size ");
      this.dataFrom.total = res.data.total;
    },
    // getInfo() {
    //   this.$http
    //     .get(`/h5sysmenu/bookpage/`, { params: {} })
    //     .then(({ data: res }) => {
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg);
    //       }
    //       console.log(res.data, "getInfo");
    //       this.dataFormPages = res.data.list;
    //     })
    //     .catch(() => {});
    // },
    scrollWindow() {
      window.addEventListener("scroll", () => {
        if (
          navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
          )
        ) {
          //显示移动端的代码
          this.getMainBox();
        }
      });
    },
    async getMainBox() {
      let Main = this.$refs.Main7;
      let bottom = this.$refs.bottom; //底部的相关DOM
      // console.log(Main.offsetTop);      //父盒子距离浏览器顶部的高度
      // console.log(window.innerHeight);  //浏览器的高度，即页面窗口的高度
      if (Main && Main.clientHeight) {
        return;
      } //父盒子的高度(加上padding和margin)
      console.log(window.scrollY); //浏览器右边的滚动条滚动距离
      if (
        Math.abs(
          Main.clientHeight -
            window.innerHeight -
            (window.scrollY - Main.offsetTop - bottom.clientHeight)
        ) <= 10
      ) {
        console.log("我滑动到底部了");
        if (this.loading) {
          return;
        }
        this.loading = true;
        this.dataFrom.page += 1;
        let { data: res } = await this.$http.get("/books/homePage", {
          params: {
            ...this.dataFrom,
          },
        });
        this.loading = false;
        this.dataList = this.dataList.concat(res.data.list);
        console.log(this.dataList, res.data.list, "this.dataLis");

        // alert('12323132')
        //这里在运用在获取新的相关数据即可
      }
    },
  },
  watch: {
    dataFrom: {
      handler(newVal) {
        let pages = Math.ceil(this.total / newVal.limit);
        this.goPage = this.dataFrom.page;
        if (pages === 0) {
          // 数据(totalResult)为0
          this.dataFrom.firstPageDisabled = true; // 首页按钮是否禁用
          this.dataFrom.lastPageDisabled = true; // 末页按钮是否禁用
        } else {
          this.dataFrom.firstPageDisabled = newVal.page === 1;
          this.dataFrom.lastPageDisabled = newVal.page === pages;
          console.log(
            pages,
            this.dataFrom.firstPageDisabled,
            this.dataFrom.lastPageDisabled,
            "pages"
          );
        }
        // if (newVal.publishRange == 0) {
        //   this.name = "长销图书";
        // } else if (newVal.publishRange == 1) {
        //   this.name = "新书出版";
        // } else if (newVal.publishRange == 2) {
        //   this.name = "系列专题";
        // } else {
        //   this.name = "全部";
        // }
      },

      // 一进页面就执行
      immediate: true,
      deep: true,
    },

    $route(to, from) {
      console.log("路由变化了2", this.defaultOpends);
      this.defaultOpends = to.query.publishRange;
      console.log("路由变化了2", this.defaultOpends);
      (this.dataFrom = {
        page: 1,
        limit: 4,
        orderField: "", //排序字段
        firstPageDisabled: false,
        lastPageDisabled: false,
        total: 0,
        order: "",
        status: 1,
        publishRange: to.query.publishRange,
      }),
        this.get();
      console.log("当前页面路由2：" + to.path);
      console.log("上一个路由2：" + from);
    },
  },
};
</script>

<style lang="scss" scoped>
.buybutton {
  display: none;
}
.banner {
  margin-top: 0.56rem;
  height: 3.3rem;
  background: linear-gradient(180deg, #b20103 0%, #f8f8f8 100%);

  span {
    position: relative;
    left: 0.84rem;
    top: 0.64rem;
    font-size: 0.48rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.56rem;
  }
}
.mian-body {
  position: relative;
  top: -1.2rem;
  height: 8.65rem;
  padding: 0 0.64rem;
  margin-bottom: -0.88rem;
  .topone {
    padding-left: 0.32rem !important;
    height: 0.56rem;
    margin-bottom: 0;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.16rem;
  }
  .topone:hover {
    // background: #ffffff;
  }
  .topone.is-active {
    // background: #ffffff;
  }
  .topone.is-active::after {
    height: 0.56rem;
  }
}
.mian-body {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 2.4rem;
  height: 9.1rem;
  background: #ffffff;
  overflow: scroll;
  .buttons {
    display: none;
  }
  .sidebarTop {
    display: none;
  }
}

.container {
  position: relative;
  box-sizing: border-box;
  padding: 0.32rem;
  padding-right: 0.66rem;
  width: 10.56rem;
  height: 9.1rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  .button {
    width: 2.4rem;
    display: flex;
    justify-content: space-between;

    cursor: pointer;

    div {
      display: flex;
      justify-content: center;

      font-size: 0.16rem;

      color: rgba(0, 0, 0, 0.85);
      width: 1.12rem;
      height: 0.4rem;
      background: rgba(238, 238, 238, 0.5);
      border-radius: 0.04rem;
      line-height: 0.4rem;
      position: relative;
      span {
        font-size: 0.16rem;
      }
    }
    .icon {
      display: flex;
      flex-direction: column;
      margin-left: 0.08rem;
      box-sizing: border-box;

      justify-content: center;
    }
    .down {
      width: 0;
      border: 0.05rem solid;
      border-top: 0.07rem solid;
      border-bottom: 0.07rem solid;
      border-color: rgba(0, 0, 0, 0.25) transparent transparent transparent;
    }
    .down_active {
      border-color: var(--backgroundColor) transparent transparent transparent;
    }
    .up {
      width: 0;
      border: 0.05rem solid;
      border-top: 0.07rem solid;
      border-bottom: 0.07rem solid;
      border-color: transparent transparent rgba(0, 0, 0, 0.25) transparent;
      margin-bottom: 0.02rem;
    }
    .up_active {
      border-color: transparent transparent var(--backgroundColor) transparent;
    }
    .select {
      display: none;
    }
  }
}

/deep/ .el-icon-arrow-down:before {
  color: var(--backgroundColor);
}
/deep/ .el-menu-item {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.48rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
  position: relative;
  margin-bottom: 0.08rem;
  font-size: 0.16rem;

  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
/deep/.is-opened {
  .el-submenu__title {
    background: rgba(195, 40, 43, 0.1);
  }
  .el-submenu__title::after {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: "";
    width: 0.04rem;
    height: 0.56rem;
    background: #c3282b;
  }
}
/deep/ .el-submenu__title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.56rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
  font-size: 0.16rem;
}
/deep/.el-menu-item:hover {
  background: #eeeeee;
}
/deep/.el-submenu__title:hover {
  // background: #ffffff;
}
/deep/ .el-menu-item.is-active {
  background: rgba(195, 40, 43, 0.1);
}
/deep/ .el-menu-item-group__title {
  padding: 0;
  height: 0.08rem;
}

.el-menu-item.is-active::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  width: 0.04rem;
  height: 0.48rem;
  background: #c3282b;
}
.books {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 80vh;

  li {
    margin-top: 0.24rem;
    display: flex;
  }
  figure {
    width: 1.6rem;
    height: 1.6rem;
    background: #ffffff;
    border: 0.01rem solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 90%;
    }
  }
  .books-msg {
    .buyiconyidong{
      display: none;
    }
    display: flex;
    flex-direction: column;
    margin-left: 0.23rem;
    span:nth-child(1) {
      line-height: 0.24rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      font-size: 0.16rem;
      margin-top: 0.12rem;
    }
    span:nth-child(2) {
      font-size: 0.14rem;
      margin-top: 0.08rem;
      line-height: 0.22rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      i {
        margin-right: 0.15rem;
      }
    }
    span:nth-child(3) {
      line-height: 0.24rem;
      margin-top: 0.16rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      font-size: 0.16rem;
      margin-top: 0.12rem;
    }
    span:nth-child(4) {
      margin-top: 0.08rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.22rem;
      width: 8.03rem;

      height: 0.44rem;

      font-size: 0.14rem;
      display: -webkit-box;
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.pagination {
  position: absolute;
  right: 0.32rem;
  //   margin-top: 0.16rem;
  bottom: 0.32rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin: 0 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    text-align: center;
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  input:focus-visible {
    outline: none;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  // /deep/ .el-input__inner {
  //   width: 0.32rem;
  //   height: 0.32rem;
  //   background: #ffffff;
  //   border-radius: 0.04rem;
  //   border: 0.01rem solid #eeeeee;
  // }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  /deep/ .el-pagination .btn-prev .el-icon {
    content: "首页";
    font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
    content: "上一页";
    font-weight: 500;
    font-size: 0.14rem;
  }
  /deep/ .el-icon-arrow-right::before {
    content: "下一页";
    font-weight: 500;
    font-size: 0.14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
  }
  /deep/.el-button span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
  }
  /deep/ .el-button.is-disabled span {
    color: #c0c4cc;
  }
  .el-pagination .btn-prev .el-icon {
    content: "首页";
    font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
    content: "上一页";
    font-weight: 500;
    font-size: 0.14rem;
  }
  /deep/ .el-icon-arrow-right::before {
    content: "下一页";
    font-weight: 500;
    font-size: 0.14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
    color: #ffffff;
  }
}

.book {
  cursor: pointer;
}

@media (max-width: 768px) {
  .index-body {
    margin-top: 0;

    padding-left: 0;

    .mian-body {
      top: 0;
      padding: 0;
      margin-bottom: 0;
      height: auto;
      width: 3.75rem;
      .container {
        padding: 0;
        width: 3.75rem;
        height: auto;
        .button {
          width: 3.75rem;
          height: 0.56rem;
          background: #ffffff;
          display: flex !important;
          justify-content: flex-end;
          align-items: center;

          div {
            background: #ffffff;
            width: 0.96rem;
            justify-content: flex-end;
            span {
              font-size: 0.14rem;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .select {
            display: flex;
            flex-grow: 1;
            justify-content: flex-start;
            margin-left: 0.16rem;
            span {
              margin-left: 0.08rem;
              display: inline-block;
              width: 1rem;
                 overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

            }
            svg {
              fill: var(--backgroundColor);
            }
          }
          .time {
            margin-right: 0.22rem;
          }
          svg {
            width: 100 !important;
            height: 100 !important;
          }
        }
        .books {
          width: 100%;
          display: block;
          li {
            margin: 0;
            margin-bottom: 0.14rem;
            margin-left: 0.15rem;
            figure {
              width: 1rem;
              height: 1rem;
            }
            .books-msg {
                   .buyiconyidong {
                display: inline-block;
                width: 0.86rem;
                height: 0.24rem;
                background: #b20103;
                border-radius: 0.04rem;
                font-size: 0.12rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 0.24rem;
                text-align: center;
              }
              span:nth-child(1) {
                margin: 0;
                font-size: 0.14rem;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
                line-height: 0.24rem;
                margin-bottom: 0.08rem;
                width: 2rem;
                overflow: hidden; // 溢出隐藏
                white-space: nowrap; // 强制一行
                text-overflow: ellipsis; // 文字溢出显示省略号
              }
              span:nth-child(2) {
                margin: 0;
                display: flex;
                i {
                  margin-right: 0.08rem;
                  display: inline-block;
                  padding: 0 0.08rem;
                  height: 0.24rem;
                  width: 0.85rem;
                  line-height: 0.24rem;
                  font-size: 0.12rem;
                  background: #f3e3e3;
                  border-radius: 0.02rem;
                  color: rgba(195, 40, 43, 1);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                i:nth-child(1) {
                  // margin-right: .02rem;
                  background: #f3e3e3;
                }
              }
              span:nth-child(3) {
                display: none;
                // content: '专题介绍';
              }
              .xilie {
                display: block !important;
                margin-top: 0;
                font-size: 0.14rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
                line-height: 0.24rem;
              }
              span:nth-child(4) {
                margin-top: 0.08rem;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                line-height: 0.22rem;
                width: 2.27rem;

                font-size: 0.12rem;
                height: 0.2rem;

                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
      .sidebar {
        display: none;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;

        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        .sidebarInner {
          width: 3rem;
          height: 100vh;
          background: #ffffff;
          border-radius: 0rem 0.16rem 0.16rem 0rem;
          overflow: scroll;
        }
        .sidebarTop {
          margin-top: 0.6rem;
          margin-bottom: 0.07rem;
          margin-left: 0.3rem;
          display: flex;
          span {
            // width: 0.64rem;
            height: 0.24rem;
            font-size: 0.16rem;

            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            line-height: 0.24rem;
            margin-left: 0.62rem;
          }
        }

        .buttons {
          margin: 0 0.56rem;
          margin-top: 0.32rem;
          display: flex;
          justify-content: space-between;
          .reset {
            width: 0.86rem;
            height: 0.32rem;
            background: #fff4f4;
            border-radius: 0.04rem;

            font-size: 0.14rem;

            font-weight: 400;
            color: #c3282b;
            line-height: 0.32rem;
            text-align: center;
          }
          .confirm {
            width: 0.86rem;
            height: 0.32rem;
            background: #c3282b;
            border-radius: 0.04rem;
            text-align: center;
            font-size: 0.14rem;

            font-weight: 400;
            color: #ffffff;
            line-height: 0.32rem;
          }
        }
      }
    }

    .banner {
      display: none;
    }
  }
  /deep/ .el-icon-arrow-left::before {
    content: "\e6de";
    color: var(--backgroundColor);
    font-size: 0.2rem;
    font-weight: 600;
  }
  /deep/ .el-submenu__title {
    height: 0.48rem;
  }
  .pagination {
    display: none;
  }
  .titlekb{
    width: 100%;
    height: 100vh;
    line-height: 80vh;
    font-size: 0.14rem;
 text-align: center;
font-weight: 400;
color: rgba(0,0,0,0.85);
background: #ffffff;
border-bottom: 1px solid #eeeeee;
 

}
}
</style>